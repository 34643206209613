s<template>

    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 my-5">

        <div class="bg-indigo-50 rounded-md mt-10">
            <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
                    <span class="block">No saved items yet.</span>
                    <span class="block text-indigo-600">Save posts, comments, and board items.</span>
                </h2>
            </div>
        </div>

        <section v-if="mySavedItems.forumPosts !== null && mySavedItems.forumPosts.length !== 0" class="mt-10" >
            <h2 class="text-gray-200 text-xs font-medium uppercase tracking-wide">Saved Posts</h2>
            <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-3">
                <saved-forum-post-card v-for="post in mySavedItems.forumPosts" :key="post.JID" :post="post"/>
            </ul>
        </section>

        <section v-if="mySavedItems.forumComments !== null && mySavedItems.forumComments.length !== 0" class="mt-10">
            <h2 class="text-gray-200 text-xs font-medium uppercase tracking-wide">Saved Comments</h2>
            <div class="grid grid-cols-2 gap-4 sm:grid-cols-3 mt-3">
                <saved-forum-comment-card v-for="comment in mySavedItems.forumComments" :key="comment.JID" :comment="comment"></saved-forum-comment-card>
            </div>
        </section>

        <section v-if="mySavedItems.contentItems !== null && mySavedItems.contentItems.length !== 0" class="mt-10">
            <h2 class="text-gray-200 text-xs font-medium uppercase tracking-wide">Saved Content Items</h2>
            <ul class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
                <saved-content-item-card v-for="contentItem in mySavedItems.contentItems" :key="contentItem.JID" :content-item="contentItem"></saved-content-item-card>
            </ul>
        </section>
    </div>
</template>

<script>
    import * as axios from "@/axios-auth"
    import SavedForumPostCard from "@/components/SavedForumPostCard";
    import SavedForumCommentCard from "@/components/SavedForumCommentCard";
    import SavedContentItemCard from "@/components/SavedContentItemCard";
    export default {
        name: "MySavedItems",
        components: {SavedContentItemCard, SavedForumCommentCard, SavedForumPostCard},
        data(){
            return {
                myGroupSessions: null,
                mySavedItems: {
                    forumPosts: null,
                    forumComments: null,
                    contentItems: null,
                },

            }
        },
        created(){
            this.fetchMySavedItems()
        },
        computed: {
            isEmpty(){
                return this.mySavedItems.forumPosts.length !== 0 &&
                        this.mySavedItems.forumComments.length !== 0 &&
                        this.mySavedItems.contentItems.length !== 0
            }
        },
        methods: {
            fetchMySavedItems(){
                axios.jigo.get(`/v2/user/mySavedItems`)
                .then((resp) => {
                    if(resp.status !== 200 && resp.status !== 201) {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                    this.mySavedItems = resp.data
                })
                .catch((err) => {
                    console.error("ERROR: searchCommunities FAILED", err)
                })
            }
        }
    }
</script>

<style scoped>

</style>
