<template>
    <div class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex flex-col items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">

        <div class="flex justify-start w-full">
            <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol class="flex items-center space-x-2">
                    <li>
                        <div>
                            <router-link tag="a" :to="`/${comment.community.owner.username}`" class="text-sm font-medium text-indigo-600 hover:text-indigo-800">{{ comment.community.owner.username }}</router-link>
                        </div>
                    </li>
                    <li>
                        <div class="flex items-center">
                            <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                            </svg>
                            <router-link tag="a" :to="`/${comment.community.owner.username}/${comment.community.name}`" class="ml-2 text-sm font-medium text-indigo-600 hover:text-indigo-800">{{ comment.community.name }}</router-link>
                        </div>
                    </li>
                </ol>
            </nav>
        </div>

        <div class="w-full flex justify-center items-center px-8 space-x-6 py-2 flex-col">
            <span class="material-icons w-full">
                format_quote
            </span>
            <div class="flex-1 truncate text-sm max-h-11">
                <jitap-clean-editor :content="comment.content"></jitap-clean-editor>
            </div>
            <span class="material-icons w-full flex justify-end">
                format_quote
            </span>
        </div>
<!--        <div class="w-full relative flex items-center space-x-3">-->
<!--            in {{ comment.post.title }}-->
<!--        </div>-->
        <div class="w-full relative flex items-center space-x-3">
            <div class="flex-shrink-0">
                <!--            <user-pop-card :prop-user="comment.creator"></user-pop-card>-->
<!--                <img class="h-10 w-10 rounded-full" :src="comment.creator.avatarUrl" alt="">-->
                <profile-image-with-pop-card size="md" :prop-user="comment.creator"></profile-image-with-pop-card>
            </div>
            <div class="flex-1 min-w-0 items-center">
                <div href="#" class="focus:outline-none flex flex-col">
<!--                    <span class="absolute inset-0" aria-hidden="true"></span>-->
                    <div class="flex items-center">
                        <span class="text-jiruto-zotDarker text-xs">by </span>
                        <router-link tag="span" :to="`/${comment.creator.username}`" class="ml-1 text-gray-800 text-sm font-bold truncate cursor-pointer">{{ comment.creator.firstName + " " + comment.creator.lastName + " @" + comment.creator.username }}</router-link>
                    </div>

                    <!--                    <p class="text-sm font-medium text-gray-900">-->
<!--                        {{ comment.creator.firstName + " " + comment.creator.lastName }}-->
<!--                    </p>-->
                    <div class="flex items-center">
                        <p class="text-jiruto-zotDarker text-xs text-center">in</p>
                        <h3 class="text-gray-900 ml-1 text-xs font-medium truncate">{{ comment.post.title + "yeahhh" }}</h3>
                    </div>

                </div>
            </div>
        </div>


    </div>
</template>

<script>
    // import UserPopCard from "@/components/UserPopCard";
    import JitapCleanEditor from "@/components/JitapCleanEditor";
    import ProfileImageWithPopCard from "@/components/ProfileImageWithPopCard";
    export default {
        name: "SavedForumCommentCard",
        components: {ProfileImageWithPopCard, JitapCleanEditor},
        // components: {UserPopCard},
        props: ['comment'],
        methods: {
            goToPost(){
                console.log(`The url is: /${this.comment.community.owner.username}/${this.comment.community.name}/discussion/posts/${this.comment.JID}`)
                this.$router.push(`/${this.comment.community.owner.username}/${this.post.comment.name}/discussion/posts/${this.comment.JID}`)
            }
        }
    }
</script>

<style scoped>

</style>
