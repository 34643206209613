<template>
    <li class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
            <div class="flex-1 truncate">
                <div class="flex flex-col">
                    <div class="flex items-center">
                        <nav class="hidden sm:flex" aria-label="Breadcrumb">
                            <ol class="flex items-center space-x-2">
                                <li>
                                    <div>
                                        <router-link tag="a" :to="`/${post.community.owner.username}`" class="text-sm font-medium text-indigo-600 hover:text-indigo-800">{{ post.community.owner.username }}</router-link>
                                    </div>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                        </svg>
                                        <router-link tag="a" :to="`/${post.community.owner.username}/${post.community.name}`" class="ml-2 text-sm font-medium text-indigo-600 hover:text-indigo-800">{{ post.community.name }}</router-link>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <h3 class="text-gray-900 text-base font-medium truncate">{{ post.title + "yeahhh" }}</h3>
<!--                    <span class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">Admin</span>-->
                </div>
                <span class="mt-1 text-jiruto-zotDarker text-xs">by </span>
                <router-link tag="span" :to="`/${post.creator.username}`" class="mt-1 text-gray-500 text-xs truncate cursor-pointer">{{ post.creator.firstName + " " + post.creator.lastName + " @" + post.creator.username }}</router-link>
            </div>
<!--            <img class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" :src="post.creator.avatarUrl" alt="">-->
            <profile-image-with-pop-card size="md" :prop-user="post.creator"></profile-image-with-pop-card>
        </div>
        <div class="w-full flex items-center px-6 py-3 space-x-6">
            <div class="flex-1 truncate text-sm h-11">
                <jitap-clean-editor :content="post.content"></jitap-clean-editor>
            </div>
        </div>
<!--        <div>-->
<!--            <div class="-mt-px flex divide-x divide-gray-200">-->
<!--                <div class="w-0 flex-1 flex">-->
<!--                    <div  class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">-->
<!--                        <jitap-clean-editor :content="post.content"></jitap-clean-editor>-->
<!--&lt;!&ndash;                        {{ post.content }}&ndash;&gt;-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </li>
</template>

<script>
    import JitapCleanEditor from "@/components/JitapCleanEditor";
    import ProfileImageWithPopCard from "@/components/ProfileImageWithPopCard";
    export default {
        name: "SavedForumPostCard",
        components: {ProfileImageWithPopCard, JitapCleanEditor},
        props: ['post'],
        methods: {
            goToPost(){
                console.log(`The url is: /${this.post.community.owner.username}/${this.post.community.name}/discussion/posts/${this.post.JID}`)
                this.$router.push(`/${this.post.community.owner.username}/${this.post.community.name}/discussion/posts/${this.post.JID}`)
            }
        }
    }
</script>

<style scoped>

</style>
