<template>
    <li class="col-span-1 flex shadow-sm rounded-md">

        <div class="flex-shrink-0 flex items-center justify-center w-20 h-20 max-w-16 bg-pink-600 text-white text-sm font-medium rounded-l-md">
            <img class="object-cover w-full h-full rounded-l-md" :src="contentItem.thumbnail" alt="">
        </div>
<!--        </img>-->
        <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">

            <div v-if="contentItem.community !== null" class="flex-1 px-4 py-2 text-sm truncate">
                <div class="flex justify-start w-full truncate">
                    <nav class="hidden sm:flex" aria-label="Breadcrumb">
                        <ol class="flex items-center space-x-2">
                            <li>
                                <div>
                                    <router-link tag="a" :to="`/${contentItem.community.owner.username}`" class="text-sm font-medium text-indigo-600 hover:text-indigo-800">{{ contentItem.community.owner.username }}</router-link>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                    <router-link tag="a" :to="`/${contentItem.community.owner.username}/${contentItem.community.name}`" class="ml-2 text-sm font-medium text-indigo-600 hover:text-indigo-800">{{ contentItem.community.name }}</router-link>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <a href="#" class="text-gray-900 font-medium hover:text-gray-600">{{ contentItem.title }}</a>
                <p class="text-gray-500">{{ contentItem.mediaType }}</p>
            </div>
<!--            <div class="flex-shrink-0 pr-2">-->
<!--                <button class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">-->
<!--                    <span class="sr-only">Open options</span>-->
<!--                    &lt;!&ndash; Heroicon name: dots-vertical &ndash;&gt;-->
<!--                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--                        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />-->
<!--                    </svg>-->
<!--                </button>-->
<!--            </div>-->
        </div>
    </li>
</template>

<script>
    export default {
        name: "SavedContentItemCard",
        props: ['contentItem'],
        goToBoard(){
            this.$router.push(`/${this.contentItem.community.owner.username}/${this.contentItem.community.name}/discussion/posts/${this.contentItem.JID}`)
        }

    }
</script>

<style scoped>

</style>
